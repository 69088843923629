<template>
  <v-overlay
    :model-value="isLoading"
    persistent
    class="align-center justify-center"
  >

    <v-row class="d-flex">
      <v-col cols="auto">

        <div class="d-flex flex-column">

          <v-progress-circular
            color="white"
            indeterminate
            size="64"
          ></v-progress-circular>

          <span>{{ loaderText }}</span>
        </div>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app';

const store = useAppStore();
const { isLoading, loaderText } = storeToRefs(store);

</script>
