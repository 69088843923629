// Composables
import { createRouter, createWebHistory } from 'vue-router';
import { authGuard } from '@auth0/auth0-vue';
import { constants } from '@/composables/enums';

const routes = [
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/Auth0CallbackPage.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/AuthSignupRedirect.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/AuthLoginRedirect.vue'),
  },
  {
    path: `/${constants.LOGOUT_ROUTE_NAME}`,
    name: constants.LOGOUT_ROUTE_NAME,
    component: () => import('@/views/AuthLogoutRedirect.vue'),
  },
  {
    path: '/quote/:quoteUuid?',
    name: 'publicquote',
    component: () => import('@/views/QuoteWebView.vue'),
  },
  {
    path: '/authcheck',
    name: 'authcheck',
    component: () => import('@/views/AuthCheckPage.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/',
    name: 'landing',
    component: () => import('@/views/LandingPage.vue'),
  },
  {
    path: '/',
    component: () => import('@/layouts/default/DefaultLayout.vue'),
    children: [

      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/HomePage.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/properties',
        name: 'properties',
        component: () => import('@/views/PropertiesPage.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/propertydetails/:uuid?',
        name: 'propertydetails',
        component: () => import('@/views/PropertyDetailsPage.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/admin',
        name: 'admin',
        component: () => import('@/views/AdminPage.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/quotedetails/:quoteUuid?',
        name: 'quotedetails',
        component: () => import('@/views/QuoteDetailsPage.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/NotFoundPage.vue'),
      },

    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
