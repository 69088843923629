<template>
  <v-snackbar
    v-model="snackbar"
    multi-line=""
    timeout="3000"
    variant="flat"
    color="green"
    location="top"
  >
    {{ text }}

    <template v-slot:actions>
      <v-btn
        color="white"
        variant="outlined"
        size="small"
        @click="store.setShowSnackBar(false)"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount } from 'vue';
import { useAppStore } from '@/store/app';

const store = useAppStore();

const text = computed(() => store.snackBarText);
const snackbar = computed({
  get: () => store.showSnackBar,
  set: (val) => {
    store.setShowSnackBar(val);
    if (val === false) {
      store.setSnackBarText('');
    }
  },
});

</script>
