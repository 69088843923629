<template>
  <div v-if="isAuth0Loading">

    <v-container v-if="!isAuth0Authenticated">
      <v-row class="d-flex align-center justify-center">
        Preparing your Hotel Quote experience...
      </v-row>
    </v-container>

  </div>
  <router-view v-else />
  <LoadingOverlay />
  <SnackBarNotification />
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { watchEffect } from 'vue';

import LoadingOverlay from './components/AppLoadingOverlay.vue';
import SnackBarNotification from './components/AppSnackBarNotification.vue';

import { useAuthStore } from './store/auth';
import { useAppStore } from './store/app';

const authStore = useAuthStore();
const appStore = useAppStore();

const { isAuth0Loading, isAuth0Authenticated } = storeToRefs(authStore);

watchEffect(
  async () => {
    if (isAuth0Loading.value) {
      appStore.setIsLoading(true);
    } else {
      appStore.setIsLoading(false);
    }
  },
);

</script>

<style>
.border-top {
  border-top: 1px solid grey;
}

.border-bottom {
  border-bottom: 1px solid grey;
}

.border-top-strong {
  border-top: 2px solid grey;
}

.summary-height {
  height: 110px;
}

.v-label {
  opacity: 1 !important;
}

.v-tooltip .v-overlay__content {
  pointer-events: initial !important;
}
</style>
