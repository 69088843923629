const auth0CustomclaimsNamespace = 'customclaims_';
export const constants = {
  AUTH0_METADATA_EXTERNAL_USER_CREATED: `${auth0CustomclaimsNamespace}external_user_created`,
  AUTH0_METADATA_UUID: `${auth0CustomclaimsNamespace}external_uuid`,
  AUTH0_METADATA_ROLES: `${auth0CustomclaimsNamespace}roles`,
  AUTH0_ROLE_ADMIN: 'Admin',
  INVALID_CREDENTIALS_OR_NO_PRIVILEDGES: 'INVALID_CREDENTIALS_OR_NO_PRIVILEDGES',
  OK: 'OK',
  PROPERTY_ALREADY_EXISTS: 'PROPERTY_ALREADY_EXISTS',
  WH_NO_AVAILABILITY: 'NO_AVAILABILITY',
  LOGOUT_ROUTE_NAME: 'logout',
  PROPERTY_EMAIL_INTRO_TEXT: 'PROPERTY_EMAIL_INTRO_TEXT',
  PROPERTY_EMAIL_NO_AVAILABILITY_TEXT: 'PROPERTY_EMAIL_NO_AVAILABILITY_TEXT',
  ROOM: 'ROOM',
  TERM: 'TERM',
  PRICING: 'PRICING',
};
Object.freeze(constants);

export const emailTemplates = {
  TYPE_AVAILABLE: 'AVAILABLE',
  TYPE_NOT_AVAILABLE: 'NOT_AVAILABLE',
  TEMPLATES_AVAILABLE: ['standard', 'simple'],
  TEMPLATES_NOT_AVILABLE: ['salt', 'pepper'],
};
Object.freeze(emailTemplates);

// export const languages = [
//   {
//     value: 'el',
//     title: 'Ελληνικά',
//   },
//   {
//     value: 'en',
//     title: 'English',
//   },
// ];
