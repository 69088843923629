// Utilities
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useAppStore = defineStore('app', () => {
  // STATE
  const isAppLoading = ref(false);
  const appLoaderText = ref('');

  const showAppSnackBar = ref(false);
  const appSnackBarText = ref('');

  // GETTERS
  const isLoading = computed(() => isAppLoading.value);
  const loaderText = computed(() => appLoaderText.value);
  const showSnackBar = computed(() => showAppSnackBar.value);
  const snackBarText = computed(() => appSnackBarText.value);

  // ACTIONS
  function setIsLoading(val) {
    isAppLoading.value = val;
  }
  function setShowSnackBar(val) {
    showAppSnackBar.value = val;
  }
  function setLoaderText(val) {
    appLoaderText.value = val;
  }
  function setSnackBarText(val) {
    appSnackBarText.value = val;
  }

  return {
    isLoading,
    loaderText,
    setIsLoading,
    setLoaderText,

    showSnackBar,
    snackBarText,
    setShowSnackBar,
    setSnackBarText,
  };
});
